body, html {
  height: 100%;
}

.App {
  position: relative;
}

.input-fields {
  display: flex;
  flex-direction: column;
}

.header {
  position: sticky;
  z-index: 999;
  height: 50px;
  width: 100%;
  background: #a71e31;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  justify-content: center;
}
.step-header {
  background: #fff;
  max-width: 800px;
  width: 100%;
  top: 0px;
  position: sticky;
  z-index: 998;
  padding-bottom: 10px;
}
.wizard-container {
  margin: auto;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.step-title {
  text-align: center;
  text-transform: uppercase;
  margin: 40px 10px 10px 10px;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.image-container {
  position: relative;
  width: 400px;
  text-align: center;
  margin: auto;
}

.color-selectors {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.color-selectors .selector {
  margin: 0 20px 0 5px;
}

.picker-wrapper {
  display: flex;
  align-items: center;
}

.picker-wrapper > span {
  padding: 4px;
}

.center {
  text-align: center;
}

.submit-modal {
  background: white;
  max-width: 300px;
  margin: 150px auto;
  padding: 40px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}

.submit-modal h2 {
  text-align: center;
}

.submit-modal p {
  text-align: center;
}

.design-image {
  z-index: 1;
  position: absolute;
  width: 35%;
  top: 25%;
  left: 32%;
}

.bg-image {
  position: relative;
  width: 100%;
  min-width: 250px;
}

.image-details {
  display: flex;
  align-items: center;
  text-align: center;
}

.image-details ul {
  list-style: none;
  padding: 5px;
  margin: auto;
}

.hex-input {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.hex-input input {
  width: 60px;
  margin-left: 5px;
}

.save-button {
  height: 35px;
  width: 225px;
  color: white;
  background: slategray;
  border: 5px solid slategray;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 0px 1;
}

/* Step Wizard */
.step-wizard {
  max-width: 90%;
  width: 100%;
  margin: 40px 0;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .image-container {
    width: 300px;
  }
}

/* Cards */
.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.card {
  width: 200px;
  text-align: center;
  justify-content: center;
  -ms-box-orient: vertical;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-align-items: stretch;
  align-items: center;
  padding: 10px;
  margin: 5px;
}

.selected {
  box-shadow: inset 0px 0px 0px 2px #a71e31;
  border-radius: 5px;
}
.card img {
  width: 170px;
}

.design {
  width: 200px;
}

.select-color {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.shirt-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.shirt-image img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: 300px;
}

.right-col {
  padding: 10px;
  display: flex;
  max-width: 400px;
  flex-direction: column;
  justify-content: center;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top:10px
}

.buttons Button {
  margin: 0 10px;
  min-width: 100px;
}

.MuiButton-containedPrimary {
  background: #a71e31;
}

.MuiButton-outlined {

}

.swatches {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.swatch {
  width: 40px;
  height: 25px;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin: 15px 15px auto;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.swatch.selected {
  border-color: #a71e31;
}

.swatch img {
  min-width: 100%;
}

/* React Step Wizard Fix */
.rsw_2f {
  display: none;
}
.rsw_3G {
  display: block;
  min-height: 100vh;
}